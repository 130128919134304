<template>
  <table-container :list="list">
    <el-table-column type="index"> </el-table-column>
    <!-- <el-table-column prop="brand_name" label="品牌"> </el-table-column> -->
    <el-table-column prop="name" label="型号名"> </el-table-column>
    <el-table-column prop="mcode" label="商家编码"> </el-table-column>
    <el-table-column prop="img_url" label="图片">
      <template slot-scope="scope">
        <ky-image
          :images="[scope.row.img_url ? base_url + scope.row.img_url : '']"
          :src="scope.row.img_url ? base_url + scope.row.img_url : ''"
        ></ky-image>
      </template>
    </el-table-column>

    <el-table-column prop="created_at" label="创建日期"> </el-table-column>
    <el-table-column label="操作">
      <template slot-scope="scope">
        <el-button type="primary" @click="handleEdit(scope.row)"
          >编辑</el-button
        >
        <delete-btn :item="scope.row" @del="handleDelete"></delete-btn
      ></template>
    </el-table-column>
  </table-container>
</template>
<script>
import KyImage from "@/components/KyImage";
export default {
  name: "List",
  components: {
    KyImage,
  },
  props: {
    list: Array,
  },
  data() {
    return {};
  },
  methods: {
    handleEdit(item) {
      this.$emit("edit", item);
    },
    async handleDelete(item) {
      const res = await this.$store.dispatch("delPhoneType", {
        type_id: item.id,
      });
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
      });
    },
  },
};
</script>
<style lang="less" scoped>
</style>