<template>
  <div class="wrapper">
    <type-nav
      @add="handleAddItem"
      @search="handleSearch"
      @change="handleBrandChange"
    ></type-nav>
    <list :list="list" @edit="handleEditItem" @succ="handleSucc"></list>
    <el-pagination
      background
      :hide-on-single-page="true"
      :total="total"
      :page-sizes="pageSizes"
      :page-size="pageSize"
      layout="total,prev, pager, next,sizes"
      @size-change="sizeChange"
      @current-change="currentChange"
    >
    </el-pagination>
    <edit-type
      :show="show"
      :type="type"
      :item="item"
      @succ="handleSucc"
    ></edit-type>
  </div>
</template>

<script>
import TypeNav from "./components/Nav";
import List from "./components/List";
import EditType from "./components/EditType";
export default {
  name: "PhoneType",
  components: { TypeNav, List, EditType },
  data() {
    return {
      type: "add",
      show: false,
      pageSizes: [10, 20, 30, 40, 50],
      pageSize: 20,
      page: 1,
      list: [],
      total: 0,
      searchText: "",
      id: "",
      brandIds: null,
      item: {},
    };
  },
  methods: {
    //搜索
    handleSearch(searchText) {
      this.searchText = searchText;
      this.getTypeList();
    },
    //选择品牌
    handleBrandChange(brandIds) {
      this.brandIds = brandIds;
      this.page = 1;
      this.getTypeList();
    },
    //获取型号列表
    async getTypeList() {
      const res = await this.$store.dispatch("getBrandFindType", {
        brand_id: JSON.stringify(this.brandIds),
        searchText: this.searchText,
        page: this.page,
        pageSize: this.pageSize,
      });
      this.list = res.data;
      this.total = res.total;
    },

    //提交成功
    handleSucc() {
      this.getTypeList();
    },
    //点击弹出新增弹窗
    handleAddItem() {
      this.show = !this.show;
      this.type = "add";
    },
    //点击弹出编辑弹窗
    handleEditItem(item) {
      this.item = item;
      this.show = !this.show;
      this.type = "edit";
    },
    //切换页面数量
    async sizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getTypeList();
    },
    //切换页数
    async currentChange(page) {
      this.page = page;
      this.getTypeList();
    },
  },
};
</script>
<style lang="less" scoped>
.wrapper {
  .el-row {
    display: flex;
    align-items: center;
    .el-dropdown {
      height: 30px;
      line-height: 30px;
      text-align: center;
      width: 100px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
  .el-table {
    margin-top: 20px;
    .red {
      color: red;
    }
  }
}
</style>
