<template>
  <el-select
    multiple
    filterable
    collapse-tags
    v-model="vals"
    :placeholder="placeholder"
  >
    <el-option label="全选" value="all"></el-option>
    <slot />
  </el-select>
</template>
<script>
export default {
  name: "Select",
  props: {
    model: Array,
    all: Array,
    placeholder: {
      type: String,
      default: "请选择",
    },
  },
  data() {
    return {
      vals: [],
    };
  },
  watch: {
    model: {
      handler(val) {
        if (val.length === this.all.length) {
          return;
        }
        this.vals = val;
      },
      deep: true,
    },
    vals(val, oldval) {
      let newIndex = val.indexOf("all"),
        oldIndex = oldval.indexOf("all");
      if (newIndex != -1 && oldIndex == -1 && val.length > 1) {
        this.vals = ["all"];
        this.$emit("change", this.all);
      } else if (newIndex != -1 && oldIndex != -1 && val.length > 1) {
        this.vals.splice(val.indexOf("all"), 1);
        this.$emit("change", this.vals);
      } else if (newIndex > -1) {
        console.log("123", this.all);
        this.$emit("change", this.all);
      } else {
        this.$emit("change", val);
      }
    },
  },
};
</script>