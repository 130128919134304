<template>
  <div class="upload-image">
    <div class="image-container" v-if="src">
      <ky-image :images="[src]" :src="src"></ky-image>
    </div>
    <el-button type="primary" class="btn"
      >上传<input
        type="file"
        ref="inpFile"
        class="inpUpload"
        multiple
        @change="getImage" /><i class="el-icon-upload el-icon--right"></i
    ></el-button>
  </div>
</template>
<script>
import KyImage from "@/components/KyImage";
export default {
  name: "UploadImage",
  components: { KyImage },
  props: {
    src: String,
  },
  methods: {
    getImage(e) {
      this.$emit("getImage", e.target.files);
      this.$refs.inpFile.value = "";
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-button {
  width: 80px;
  position: relative;
  .inpUpload {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
.upload-image {
  width: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;
  .image-container {
    position: relative;
    margin-right: 10px;
    width: 100px;
    height: 100px;
  }
}
</style>