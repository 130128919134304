<template>
  <el-row :gutter="20">
    <el-col :span="3">
      <all-select
        :model="brandIds"
        :all="allBrandId"
        placeholder="品牌选择"
        @change="handleCheckedBrand"
      >
        <el-option
          v-for="item in brandList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </all-select>
    </el-col>
    <el-col :span="4">
      <el-input
        placeholder="请输入型号关键字"
        v-model="searchVal"
        class="input-with-select"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="handleSearch"
        ></el-button>
      </el-input>
    </el-col>
    <el-col :span="3">
      <el-button type="primary" @click="handleAdd" icon="el-icon-plus"
        >新增型号</el-button
      >
    </el-col>
  </el-row>
</template>
<script>
import { mapState } from "vuex";
import AllSelect from "@/components/AllSelect";
export default {
  name: "Nav",
  components: { AllSelect },
  data() {
    return {
      searchVal: "",
      brandIds: [],
      allBrandId: [],
    };
  },
  computed: {
    ...mapState(["brandList"]),
  },
  created() {
    this.handleInit();
  },
  methods: {
    //初始化
    async handleInit() {
      await this.$store.dispatch("getAllBrandList");
      this.brandIds = [this.brandList[0].id];
      this.allBrandId = this.brandList.map((item) => item.id);
      this.$emit("change", this.brandIds);
    },
    //选中品牌
    handleCheckedBrand(param) {
      this.brandIds = param;
      this.$emit("change", param);
    },
    //选择品牌
    handleSelecBrand() {
      this.$emit("change", this.brandId);
    },
    //搜索
    handleSearch() {
      this.$emit("search", this.searchVal);
    },
    //新增
    handleAdd() {
      this.$emit("add");
    },
  },
};
</script>
<style lang="less" scoped>
</style>